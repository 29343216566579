<template>
	<div class="gray-bg min-height-80vh">
		<div class="modal fade" id="termsModal">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header align-items-center">
						<button type="button" class="close modal_close" data-dismiss="modal"><i class="ti-close"></i></button>
					</div>
					<div class="modal-body termsBody">
						<div v-html="pageTerms['body' + capitalizeFirstCharLang()]"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="breadcrump">
				<h4 class="py-3 mb-4"><span class="text-muted fw-light"><a href="/">{{ $j('Homepage') }}</a> /</span> {{ $j('Deposition') }}</h4>
			</div>

			<div class="card-box step-card">
				<div class="step-tab">
					<ul>
						<li class="first current" id="step1">
							<div class="tab-title d-flex" @click="nextOrPrevStep(currentTab, 1)">
							<!-- <div class="tab-title d-flex"> -->
								<span class="step-number">1</span>
								<span class="step-text">{{ $j('DataEntry') }}</span>
							</div>
						</li>
						<li class="second" id="step2">
							<div class="tab-title d-flex" @click="nextOrPrevStep(currentTab, 2)">
							<!-- <div class="tab-title d-flex"> -->
								<span class="step-number">2</span>
								<span class="step-text">{{ $j('FileUpload') }}</span>
							</div>
						</li>
						<li class="third" id="step3">
							<div class="tab-title d-flex" @click="nextOrPrevStep(currentTab, 3)">
							<!-- <div class="tab-title d-flex"> -->
								<span class="step-number">3</span>
								<span class="step-text">{{ $j('DataVerification') }}</span>
							</div>
						</li>
						<li class="fourth" id="step4">
							<div class="tab-title d-flex">
								<span class="step-number">4</span>
								<span class="step-text">{{ $j('Payment') }}</span>
							</div>
						</li>
					</ul>
				</div>
				<div class="step-content">
					<fieldset id="tab1">
						<h2 class="text-muted">{{ $j('DepositionProcess') }}</h2>
						<p class="desc text-muted">{{ $j('ProcessText') }}</p>
						<el-form :model="model" ref="dynamicForm" class="fieldset-content demo-dynamic">
							<div class="row justify-content-center align-items-center">
								<div :class="model.isFromLegalUser == true ? 'col-md-4' : 'col-md-8'">
									<div class="form-group">
										<input type="text" :placeholder="$j('HandwritingName')" v-model="model.productTitle" @input="capitalizeFirstLetter('productTitle')" class="form-control">
									</div>
								</div>
								<div class="col-md-4" v-if="model.isFromLegalUser == false">
									<div class="form-group">
										<label class="owner-label">{{ $j("OwnAuthor") }}
											<input type="checkbox" @change="filterCheck()">
											<span class="checkmark"></span>
										</label>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<select v-model="model.productionTypeId" placeholder="Toifa" class="form-control" @change="changePiece($event)">
											<option :label="$j('PieceType')" value="0"></option>
											<option v-for="(key, value) in pieces" :label="key.typeDescription" :value="key.id" :key="value"></option>
										</select>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group piece-date">
										<date-picker type="date" v-model="model.productionDate" :placeholder="$j('PieceDate')" @change="changeDate()"></date-picker>
									</div>
								</div>
								<div class="col-md-4" v-if="model.isFromLegalUser == false">
									<div class="form-group">
										<span class="add-author cursor-pointer" @click="addForm()"> <i class="mdi mdi-account-plus-outline"></i> {{ $j("AddCoAuthor") }}</span>
									</div>
								</div>
								<div class="row col-md-12 clear-pr-pl" v-for="(item, index) in model.additionalProductOwners" :key="index">
									<div class="col-md-3 pr-5px">
										<el-form-item
											:prop="'additionalProductOwners.' + index + '.lastname'"
											:rules="[{ required: true, message: $j('LastName') + ' ' + $j('notEntered'), trigger: 'blur' }]">
											<el-input v-model="item.lastname" :placeholder="$j('LastName')"></el-input>
										</el-form-item>
									</div>
									<div class="col-md-3 pr-5px">
										<el-form-item
											:prop="'additionalProductOwners.' + index + '.firstName'"
											:rules="[{ required: true, message: $j('FirstName') + ' ' + $j('notEntered'), trigger: 'blur' }]">
											<el-input v-model="item.firstName" :placeholder="$j('FirstName')"></el-input>
										</el-form-item>
									</div>
									<div class="col-md-4 pr-5px">
										<el-form-item
											:prop="'additionalProductOwners.' + index + '.midName'">
											<el-input v-model="item.midName" :placeholder="$j('MiddleName')"></el-input>
										</el-form-item>
									</div>
									<div class="col-md-2 mb-3">
										<span class="btn btn-danger cursor-pointer" @click="removeForm(item)">
											<i class="mdi mdi-trash-can-outline"></i>
										</span>
									</div>
								</div>
								<div class="col-md-12" v-if="model.isFromLegalUser == false && model.ownerCheck == false">
									<div class="form-group">
										<input type="text" :placeholder="$j('OwnerRights')" v-model="model.productOwner" @input="capitalizeFirstLetter('productOwner')" class="form-control">
									</div>
								</div>
							</div>
						</el-form>
						<div class="step-action">
							<ul class="justify-content-end">
								<li @click="nextOrPrevStep(1, 2)"><span class="btn btn-gold">{{ $j('Next') }}</span></li>
							</ul>
						</div>
					</fieldset>

					<fieldset id="tab2" style="display: none;">
						<div class="form-container">
							<div class="upload-files-container">
								<div class="drag-file-area" @drop="draggableFile($event)">
									<i class="mdi mdi-progress-download upload-icon text-muted"></i>
									<h4 class="dynamic-message text-muted"> {{ $j('DropFiles') }}</h4>
									<label class="label">
										<span class="browse-files text-muted">
											<input type="file" class="default-file-input" @click="clickFile()"
												@change="changeFile()" />
											<span class="browse-files-text">{{ $j("SelectFromDevice") }}</span>
										</span>
									</label>
								</div>
								<div class="file-block">
									<div class="file-info">
										<span class="mdi mdi-file-document file-icon"></span>
										<span class="file-name"> </span> | <span class="file-size"> </span>
									</div>
									<span class="mdi mdi-delete remove-file-icon" @click="removeFile()"></span>
								</div>
							</div>
						</div>
						<div class="step-action">
							<ul>
								<li @click="nextOrPrevStep(2, 1)"><span class="btn btn-back">{{ $j('Prev') }}</span></li>
								<li @click="nextOrPrevStep(2, 3)"><span class="btn btn-gold">{{ $j('Next') }}</span></li>
							</ul>
						</div>
					</fieldset>

					<fieldset id="tab3" style="display: none;">
						<div class="row check-card">
							<div class="col-md-12">
								<div class="card-box">
									<ul class="list-unstyled mb-0">
										<li class="d-flex align-items-center mb-2 pb-1">
											<div class="avatar flex-shrink-0 mr-3">
												<span class="avatar-initial rounded bg-label-primary">
													<i class="mdi mdi-18px mdi-account-outline"></i>
												</span>
											</div>
											<div class="row w-100 align-items-center">
												<div class="col-sm-5 mb-1">
													<h6 class="mb-0 lh-sm">{{ $j('Applicant') }}</h6>
												</div>
												<div class="col-sm-7">
													<div class="user-info bg-label-secondary">
														<span v-if="model.isFromLegalUser == false">{{ user.fullName }}</span> 
														<span v-else>{{ user.legalName }}</span> 
													</div>
												</div>
											</div>
										</li>
										<li class="d-flex align-items-center mb-2 pb-1">
											<div class="avatar flex-shrink-0 mr-3">
												<span class="avatar-initial rounded bg-label-primary">
													<i class="mdi mdi-18px mdi-account-card-details"></i>
												</span>
											</div>
											<div class="row w-100 align-items-center" v-if="model.isFromLegalUser == false">
												<div class="col-sm-5 mb-1">
													<h6 class="mb-0 lh-sm">JSHSHIR</h6>
												</div>
												<div class="col-sm-7">
													<div class="user-info bg-label-secondary">{{ user.userPnfl }}</div>
												</div>
											</div>
											<div class="row w-100 align-items-center" v-else>
												<div class="col-sm-5 mb-1">
													<h6 class="mb-0 lh-sm">STIR</h6>
												</div>
												<div class="col-sm-7">
													<div class="user-info bg-label-secondary">{{ user.legalInn }}</div>
												</div>
											</div>
										</li>
										<li class="d-flex align-items-center mb-2 pb-1" v-if="model.isFromLegalUser == false">
											<div class="avatar flex-shrink-0 mr-3">
												<span class="avatar-initial rounded bg-label-primary">
													<i class="mdi mdi-18px mdi-home-outline"></i>
												</span>
											</div>
											<div class="row w-100 align-items-center">
												<div class="col-sm-5 mb-1">
													<h6 class="mb-0 lh-sm">{{ $j('Address') }}</h6>
												</div>
												<div class="col-sm-7">
													<div class="user-info bg-label-secondary">
														<span>{{ user.address }}</span> 
													</div>
												</div>
											</div>
										</li>
										<li class="d-flex align-items-center mb-2 pb-1">
											<div class="avatar flex-shrink-0 mr-3">
												<span class="avatar-initial rounded bg-label-primary">
													<i class="mdi mdi-18px mdi-filmstrip"></i>
												</span>
											</div>
											<div class="row w-100 align-items-center">
												<div class="col-sm-5 mb-1">
													<h6 class="mb-0 lh-sm">{{ $j('HandwritingName') }}</h6>
												</div>
												<div class="col-sm-7">
													<div class="user-info bg-label-secondary"> {{ model.productTitle }} </div>
												</div>
											</div>
										</li>
										<li class="d-flex align-items-center mb-2 pb-1">
											<div class="avatar flex-shrink-0 mr-3">
												<span class="avatar-initial rounded bg-label-primary">
													<i class="mdi mdi-18px mdi-file-document-box-outline"></i>
												</span>
											</div>
											<div class="row w-100 align-items-center">
												<div class="col-sm-5 mb-1">
													<h6 class="mb-0 lh-sm">{{ $j('PieceType') }}</h6>
												</div>
												<div class="col-sm-7">
													<div class="user-info bg-label-secondary">{{ pieceName }}</div>
												</div>
											</div>
										</li>	
										<li class="d-flex align-items-center mb-2 pb-1">
											<div class="avatar flex-shrink-0 mr-3">
												<span class="avatar-initial rounded bg-label-primary">
													<i class="mdi mdi-18px mdi-calendar"></i>
												</span>
											</div>
											<div class="row w-100 align-items-center">
												<div class="col-sm-5 mb-1">
													<h6 class="mb-0 lh-sm">{{ $j('PieceDate') }}</h6>
												</div>
												<div class="col-sm-7">
													<div class="user-info bg-label-secondary"> {{ createPieceDate }} </div>
												</div>
											</div>
										</li>
										<li class="d-flex align-items-center mb-2 pb-1" v-if="model.isFromLegalUser == false && model.ownerCheck == false">
											<div class="avatar flex-shrink-0 mr-3">
												<span class="avatar-initial rounded bg-label-primary">
													<i class="mdi mdi-18px mdi-account-check-outline"></i>
												</span>
											</div>
											<div class="row w-100 align-items-center">
												<div class="col-sm-5 mb-1">
													<h6 class="mb-0 lh-sm">{{ $j('OwnerRights') }}</h6>
												</div>
												<div class="col-sm-7">
													<div class="user-info bg-label-secondary"> {{ model.productOwner }} </div>
												</div>
											</div>
										</li>
										<li class="d-flex align-items-center">
											<div class="avatar flex-shrink-0 mr-3">
												<span class="avatar-initial rounded bg-label-primary">
													<i class="mdi mdi-18px mdi-cash"></i>
												</span>
											</div>
											<div class="row w-100 align-items-center">
												<div class="col-sm-5 mb-1">
													<h6 class="mb-0 lh-sm">{{ $j('ServicePrice') }}</h6>
												</div>
												<div class="col-sm-7">
													<div class="user-info bg-label-secondary" v-if="model.isFromLegalUser == true || model.ownerCheck == false">BHM ning 3 baravari</div>
													<div class="user-info bg-label-secondary" v-else>400 000 so‘m</div>
												</div>
											</div>
										</li>
									</ul>
								</div>								
								<section class="loading-area" v-if="showProgress">
									<li class="row-loading" v-for="(file, index) in files" :key="index">
										<i class="fas fa-file-alt"></i>
										<div class="content">
											<div class="details">
												<span class="name">{{ file.name }}</span>
												<span class="percent">{{ file.loading }} %</span>
											</div>
											<div class="loading-bar">
												<span class="loading" :style="{ width: file.loading + '%' }"></span>
											</div>
										</div>
									</li>
								</section>
							</div>
						</div>
						<div class="step-action">
							<div class="d-flex justify-content-around align-items-center mt-4">
								<label class="container-checkbox">{{ $j('termsAccept') }} 
									<!-- <router-link to="/page/deposit-conditions"> {{ $j('termsPage') }}</router-link> -->
									<input type="checkbox" id="terms-checkbox">
									<span class="checkmark"></span>
								</label>
								<span class="terms-span" data-toggle="modal" data-target="#termsModal">{{ $j('termsPage') }}</span>
								<div class="cursor-pointer">
									<span :class="isLoading ? `btn btn-gold send-disabled` : `btn btn-gold`" @click="createModel('dynamicForm')" v-if="token">
										<span v-if="isLoading">
											<img src="/assets/img/loading_gif/4.gif" alt="loading" width="20" height="20" /> {{ $j('Sending') }}...
										</span>
      									<span v-else>{{ $j('Save') }}</span>
									</span>
									<span class="btn btn-gold login-oneid-button" data-toggle="modal" data-target="#oneIdModal" v-else>{{ $j('Save') }}</span>
								</div>
							</div>
						</div>
					</fieldset>
					
					<fieldset id="tab4" style="display: none;">
						<div class="row payment_details">
							<div class="col-md-6 col-sm-6">
								<div class="payment_widget">
									<div class="payment_single mb-4">
										<span class="ti-wallet"></span>
										<p>{{ $j('PaymentText') }}</p>
									</div>
									<div class="payment_single">
										<span class="ti-time"></span>
										<p>{{ $j('PaymentText2') }}</p>
									</div>
								</div>
							</div>
							<div class="col-md-6 col-sm-6 payment-border-left">
								<div class="single-element-widget mt-30">
									<div class="switch-wrap d-flex justify-content-center align-items-center pb-4">
										<div class="primary-radio">
											<input type="radio" id="default-radio" disabled name="radio_payment">
											<label for="default-radio"></label>
										</div>
										<img src="/assets/img/payment/click-01.png">
									</div>
									<div class="switch-wrap d-flex justify-content-center align-items-center">
										<div class="primary-radio">
											<input type="radio" id="primary-radio" @change="changeRadio()" name="radio_payment">
											<label for="primary-radio"></label>
										</div>
										<img src="/assets/img/payment/payme-01.png">
									</div>
								</div>
							</div>
							<div class="col-md-12 mt-3" v-if="model.isFromLegalUser || model.ownerCheck == false">
								<mark>{{ $j("legalPayInfo") }}</mark>
							</div>
						</div>
						<div class="step-action pt-4" v-if="!model.isFromLegalUser && model.ownerCheck == true">
							<ul class="justify-content-end">
								<li><span id="payButton" class="btn btn-gold disabled" @click="paymentClick()">{{ $j('ProceedToPayment') }}</span></li>
							</ul>
						</div>
					</fieldset>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import DatePicker from 'vue2-datepicker';
export default {
	components: { DatePicker },
	data() {
		return {
			files: [],
			pieces: [],
			pageTerms: {},
			user: {
				fullName: "",
				passportNumber: "",
				address: "",
			},
			showProgress: false,
			isLoading: false,
			payResult: {},
			
			model: { 	id: "", productTitle: "", productionTypeId: 0, productionDate: null, productOwner: "", ownerCheck: false, applicantAddress:".", 
						isFromLegalUser: false, additionalProductOwners: [] },
			clearModel: { id: "", productTitle: "", productionTypeId: 0, productionDate: null, productOwner: "", ownerCheck: false, applicantAddress:".", 
						isFromLegalUser: false, additionalProductOwners: [] },

			fileInput: "",
			fileSpan: "",
			fileName: "",
			uploadedFile: "",
			token: "",
			currentTab: 1,
			pieceName: "",
			createPieceDate: null
		};
	},
	methods: {

		//#region File Upload
		clickFile() {
			this.fileInput.value = "";
		},
		changeFile() {
			this.infoFile(this.fileInput.files[0]);
		},
		draggableFile(e) {
			let files = e.dataTransfer.files;
			this.fileInput.files = files;
			this.infoFile(files[0]);
		},
		infoFile(file) {
			this.fileName = (file.name.length >= 15) ? file.name.substring(0, 15) + '...' + file.name.split('.')[1] : file.name;
			this.fileSpan.innerHTML = this.fileName;
			let fileSize = document.querySelector(".file-size")
			fileSize.innerHTML = (file.size < (1024 * 1024)) ? (file.size / 1024).toFixed(1) + ' KB' : (file.size / (1024 * 1024)).toFixed(2) + ' MB';			
			this.uploadedFile.style.cssText = "display: flex;";
		},
		removeFile() {
			this.uploadedFile.style.cssText = "display: none;";
			this.fileInput.value = "";
		},
		handleDragover() {
			let draggableFileArea = document.querySelector(".drag-file-area");
			[
				"dragover",
				"drop",
			].forEach((evt) =>
				draggableFileArea.addEventListener(evt, (e) => {
					e.preventDefault();
					e.stopPropagation();
				})
			);
		},
		//#endregion

		//#region SaveData
		
		createModel(formName) {
			var checkbox = document.getElementById("terms-checkbox");
			if(checkbox.checked == false){
				return this.$store.getters.toastr(this, "error", "notSelected", "termsNotClick");
			}
			//#region Check			
			
			this.model.productionDate = this.$store.getters.formatDate(this.model.productionDate);
			if(this.model.isFromLegalUser == true){
				this.model.productOwner = this.user.legalName;
				this.model.applicantInn = this.user.legalInn;
				this.model.applicant = "";
			}
			else {
				this.model.applicant = this.user.fullName;
				this.model.applicantAddress = this.user.address;				
				this.model.applicantInn = "";				
			}
			if (this.model.id != "") {
				this.nextOrPrevStep(this.currentTab, 4);
				return false;
			}
			//#endregion
			
			const formData = this.getForm();
			
			const headers = this.$store.getters.getHeader(this);
			this.$refs[formName].validate((valid) => {
				if (valid) {
					let fileSizeChek = this.fileInput.files[0].size / (1024 * 1024);
					this.files.push({ name: this.fileName, loading: 0 });
					if(fileSizeChek > 1){
						this.showProgress = true;
						this.isLoading = true;
					}
					this.sendData(headers, formData);
				} else {
					this.nextOrPrevStep(this.currentTab, 1);
					this.$store.getters.toastr(this, "error", "Mualliflar maydonlar to‘ldirilmadi","");
				}
			});
		},
		getForm() {
			var language = localStorage.getItem("lang");
			var langId = this.caseLang(language);
			const formData = new FormData();
			formData.append("Applicant", this.model.applicant);
			formData.append("ApplicantInn", this.model.applicantInn);
			formData.append("ProductTitle", this.model.productTitle);
			formData.append("ProductionTypeId", this.model.productionTypeId);
			formData.append("ProductionDate", this.model.productionDate);
			formData.append("ProductOwner", this.model.productOwner);
			formData.append("ApplicantAddress", this.model.applicantAddress);
			formData.append("ApplicationLanguage", langId);
			formData.append("IsFromLegalUser", this.model.isFromLegalUser);
			formData.append("AppProductOwners", JSON.stringify( this.model.additionalProductOwners));
			
			formData.append("ApplicationFile", this.fileInput.files[0]);
			return formData;
		},
		sendData(headers, formData) {
			var _this = this;
			axios({
				method: "post",
				url: this.$store.state.url + "/certificate_service/api/v1/application/add_application",
				headers: headers,
				data: formData,
				onUploadProgress: ({ loaded, total }) => {
					console.log(_this.files);
					_this.files[0].loading = Math.floor((loaded / total) * 100);
					if (loaded == total) {
						_this.files = [];
						_this.showProgress = false;
					}
				}
			}).then((response) => {
				this.isLoading = false;
				this.model.id = response.data.result.id;
				this.payResult = response.data.result;
				this.nextOrPrevStep(this.currentTab, 4);
			},
				err => {
					let message = err.response.data.title;
					if(!message){
						message = err.response.data.error.errorMessage;
					}
					this.$store.getters.toastr(this, "error", message, "");
				}
			);
		},
		changeRadio(){
			var button = document.getElementById("payButton");
			button.classList.remove("disabled");
		},
		caseLang(lang){
			var result = 1;
			switch (lang)
			{
				case "eng": result = 2; break;
				case "rus": result = 3; break;
				default: break;
			}
			return result;
		},
		paymentClick(){
			var query = "m=668f8445d1602a403ae3c335;ac.order_id=" + this.payResult.orderId + ";ac.user_id=" + this.payResult.userId + ";a=" + this.payResult.paymentAmount;
			var base64 = btoa(query);
			const link = document.createElement("a");
			link.href = "https://checkout.paycom.uz/" + base64;
			document.body.appendChild(link);
			link.click();
		},
		//#endregion

		//#region Steps	
		nextOrPrevStep(currNumber, prevNextNumber) {
			if(currNumber == prevNextNumber){
				return false;
			}
			if(currNumber < prevNextNumber){
				var result = true;
				switch (currNumber)
				{
					case 1: result = this.step1Check(); break;
					case 2: result = this.step2Check(); break;
					default: break;
				}
				if(result == false){
					return false;
				}
			}
			this.currentTab = prevNextNumber;
			var current = document.getElementById("tab" + currNumber);
			var next_prev = document.getElementById("tab" + prevNextNumber);

			current.style.display = 'none';
			next_prev.style.display = 'block';

			document.getElementById("step" + currNumber).classList.remove("current");
			document.getElementById("step" + prevNextNumber).className = "current";

			this.fadeOut(current, next_prev, 600);
		},

		fadeOut(current_fs, previous_fs, duration) {
			var opacity = 1;
			var interval = 10;
			var gap = interval / duration;

			function fade() {
				opacity -= gap;
				if (opacity <= 0) {
					opacity = 0;
					current_fs.style.display = 'none';
					current_fs.style.position = 'relative';
					previous_fs.style.opacity = 1;
				} else {
					current_fs.style.opacity = opacity;
					previous_fs.style.opacity = 1 - opacity;
					setTimeout(fade, interval);
				}
			}

			fade();
		},
		step1Check() {
			if (this.model.productTitle == "") {
				this.$store.getters.toastr(this, "error", "notEntered", "HandwritingName");
				return false;
			}
			if (this.model.productionTypeId == 0) {
				this.$store.getters.toastr(this, "error", "notSelected", "PieceType");
				return false;
			}
			if (this.model.productionDate == null) {
				this.$store.getters.toastr(this, "error", "notSelected", "PieceDate");
				return false;
			}
			if (this.model.ownerCheck == false && this.model.productOwner == "") {
				this.$store.getters.toastr(this, "error", "notEntered", "OwnerRights");
				return false;
			}
			else {
				this.model.productOwner == "";
			}
			return true;
		},
		step2Check() {			
			if (this.fileInput.files.length == 0) {
				this.$store.getters.toastr(this, "error", "notSelected", "File");
				return false;
			}
			return true;
		},
		//#endregion
		
		//#region Helper
		getPieces() {
			let typeId = 1;
			if(!this.model.isFromLegalUser){
				typeId = 2;
			}
			var _this = this;
			this.$api.get("/content_service/api/v1/production_types/get_production_type?producerType=" + typeId).then(response => {
				_this.pieces = response.result.data;
			},
			err => {
				this.$store.getters.errorParse(this, err);
			})
		},
		changePiece(select) {
			var val = select.currentTarget.value;
			var piece = this.pieces.firstOrDefault(m => m.id == val)
			if(val != 0){
				this.pieceName = piece.typeDescription;
			}
		},
		getTerms() {
			this.$api.get("/query_service/api/v1/dynamic_pages/get_dynamic_pages?pagePath=deposit-conditions&limit=10").then(
            response => {
				this.pageTerms = response.result.data[0];
			},
			err => {
				this.$store.getters.errorParse(this, err);
			})
		},
		capitalizeFirstCharLang() {
			let result = this.$store.state.langKod.charAt(0).toUpperCase() + this.$store.state.langKod.slice(1);
			return result.slice(0, -1)
		},
		changeDate() {
			this.createPieceDate = moment(this.model.productionDate).format('DD.MM.YYYY');
		},
		filterCheck(){
			this.model.ownerCheck = !this.model.ownerCheck;
			this.model.productOwner = "";
		},
		addForm() {
			this.model.additionalProductOwners.push({
				firstName: "",
				lastname: "",
				midName: "",
			});
		},
		removeForm(item) {
			var index = this.model.additionalProductOwners.indexOf(item);
			if (index !== -1) {
				this.model.additionalProductOwners.splice(index, 1);
			}
		},
		capitalizeFirstLetter(field) {
			if (this.model[field].length > 0) {
				this.model[field] = this.model[field].charAt(0).toUpperCase() + this.model[field].slice(1);
			}
		}
		//#endregion
	},
	mounted() {
		this.handleDragover();
		let userModel = this.$store.getters.getUserModel();
		if(userModel){
			this.user = userModel;
			this.model.isFromLegalUser = userModel.isLegal;
		}
		this.getPieces();
		//this.getTerms();
		this.token = this.$store.getters.getToken();
		this.fileInput = document.querySelector(".default-file-input");
		this.fileSpan = document.querySelector(".file-name");
		this.uploadedFile = document.querySelector(".file-block");
	}
}
</script>